import React, { Fragment, useEffect } from 'react';

import Aos from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';

const ProjectCard = ( {img, projAlt, projTitle, projDesc, projId} ) => {
  
  useEffect(() => {
    Aos.init({once: true});
  } , [])
  return (
    <Fragment>
      
        <Link to={`/nos-projets/${projId}`} className="block max-w-[303px]">
          <div 
          className="project-card md:h-full w-full lg:w-full xl:w-full 2xl:max-w-[303px]" 
          data-aos="zoom-in"
          data-aos-delay={`${300 * projId}`}
          >
              <div className="img-project w-full md:h-[258px] lg:h-[250px]"><img src={img} alt={projAlt} className="object-cover w-full h-full" /></div>
              <div className="projet-desc lg:pr-32 xl:pr-4 py-[22px] bg-black-50 px-[16px]">
                  <h5 className="text-[20px] font-semibold text-blue-600 whitespace-nowrap overflow-hidden text-ellipsis">{projTitle}</h5>
                  <p className="text-[12px] project-desc">{projDesc}</p>
              </div>
          </div>
        </Link>
    </Fragment>
  )
}

export default ProjectCard