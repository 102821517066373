import React, { Fragment, useContext } from "react";
import projectData from "../../utils/constants/projectData";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ProjectCard from "../../components/UI/ProjectCard";
import { useTranslation } from "react-i18next";
import { ProjectContext, DomaineContext } from "./Projet";

const ProjetBlock = ({ projectObject, index, linkId }) => {
  const projects = projectData.projects;
  const { t } = useTranslation("projets");
  const projectList = []; // array for the list of projects.

  projects.map((project) => {
    if (project.domaineId == index+1) {
      projectList.push(project);
    }
  });

  console.log(projectList);

  return (
    <Fragment>
      <div className="proj-block 2xl:max-w-[884px] 2xl:mx-auto" id={linkId}>
        <div className="big-text-container my-9 md:my-[90px] text-blue-600 font-semibold text-center md:text-[6px]">
          <span className="uppercase text-[32px] sm:text-[42px] font-bold py-8 md:text-[38px] big-big-heading">
            {t(`${projectObject}.${index}.name`)}
          </span>
        </div>
        <div className="proj-block-list hidden md:px-8 sm:grid sm:grid-cols-2 sm:gap-x-4 sm:gap-y-8 lg:grid lg:grid-cols-3 lg:gap-x-6 lg:gap-y-6 ">
          {projectList.map((item) => {
            return (
              <ProjectCard
                key={item.id}
                projTitle={item.name}
                projDesc={item.description}
                img={item.imgs[0]}
                projId={item.id}
              />
            );
          })}
        </div>

        <div className="flex flex-col items-center justify-center gap-y-4 sm:hidden">
          {projectList.map((item) => {
            return (
           <ProjectCard
                key={item.id}
                projTitle={item.name}
                projDesc={item.description}
                img={item.imgs[0]}
                projId={item.id}
              />
            );
          })}
        </div>
      </div>
    </Fragment>
  );
};

export default ProjetBlock;
