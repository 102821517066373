import React, { Fragment, useState } from "react";

const Input = ({ label, name, type }) => {
  const [value, setValue] = useState("");
  const handleInput = (e) => {
    e.preventDefault();
   const  InputValue =e.target.value ;
    setValue(InputValue) ;
  };
  return (
    <Fragment>
      <div className="form-group w-full flex flex-col gap-y-3">
        <input
          type={type}
          name={name}
          placeholder={label}
          value={value}
          className="w-full bg-white p-[16px] h-[57px] focus:ring-1 focus:ring-blue-200 focus:outline-blue-500 text-black-700"

          onChange={(e)=>handleInput(e)}
        />
      </div>
    </Fragment>
  );
};

export default Input;
