import React, { Fragment } from 'react'

import Carousel from 'nuka-carousel'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const CardService = ( {title, content, list, alt, idLink, serviceTrans} ) => {

    const { t } = useTranslation("service")

    const settings = {
        infinite: true,
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        lazyLoad: true,
        autoplay: true,
        arrows: false,
        autoplaySpeed: 3000,
    }
  return (
    <Fragment>
        <div className="card-service mb-[142px] " id={idLink}>
            <h4 className="headline-underline text-[24px] text-blue-900 capitalize font-semibold before:absolute before:w-[80px]">{title}</h4>

            <p className="content mt-[84px]">{content}</p>

            <div className="hidden img-wrapper mt-[58px] lg:grid lg:grid-cols-3 flex-col justify-center sm:flex-row sm:gap-x-4 items-center gap-y-8">
                {
                    list.map((item, index) => (
                        <Link to={``} key={item.id}>
                            <div className="img-card">
                                <img className="h-full object-cover" src={item.src} alt={alt} />
                                <div className="desc">
                                    <h6 className="text-md text-blue-100">{t(`${serviceTrans}.projectList.${index}.projects.${index}.projName`)}</h6>
                                    <p>{t(`${serviceTrans}.projectList.${index}.projects.${index}.projDesc`)}</p>
                                </div>
                            </div>
                        </Link>
                    ))
                }
            </div>
            <div className="lg:hidden mt-[50px] md:max-w-[800px] md:mx-auto">
                <Carousel
                slidesToScroll={1}
                slidesToShow={2}
                wrapAround={true}
                swiping={true}
                autoplayInterval={2000}
                autoplay={true}
                animation='zoom'
                withoutControls={true}
                >
                    {
                        list.map((item) => (
                            <Link to={"/proj-details"} key={item.id}>
                                <div className="img-card h-full">
                                    <img src={item.src} alt={item.projTitle} className="w-full h-full object-cover" />
                                    <div className="desc">
                                        <h6 className="text-md text-blue-100">{item.projTitle}</h6>
                                        <p>{item.desc}</p>
                                    </div>
                                </div>
                            </Link>
                        ))
                    }
                </Carousel>
            </div>
        </div>
    </Fragment>
  )
}

export default CardService