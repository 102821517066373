import React, { Fragment, useEffect, useState } from "react";

import { GiHamburgerMenu } from "react-icons/gi";
import { MdClose } from "react-icons/md";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { data, image } from "../../utils/constants";
import CallToAction from "../UI/CallToAction";
import LinkItem from "./LinkItem";
import MobileLink from "./MobileLink";
import Dropdown from "../UI/Dropdown";
import DropdownLinkItem from "./DropdownLinkItem";
import MobileDropDownLinkItem from "./MobileDropDownLinkItem";
import MobileDropDownLinkItemClose from "./MobileDropDownLinkItemClose";

const Navbar = () => {
  const [hasActive, setActive] = useState(false);
  const [isSticky, setSticky] = useState(false);
  const [isOpened, setOpen] = useState(false);
  const [isItOpen, setIsOpen] = useState(false);
  const { t } = useTranslation("common");

  const stickNavbar = () => {
    let windowHeight = window.scrollY;
    if (windowHeight > 200) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };

  const handleOpen = () => {
    setOpen((prevState) => !prevState);
  };

  const handleActive = () => {
    setActive((prevState) => !prevState);
  };

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);
    return () => window.removeEventListener("scroll", stickNavbar);
  }, []);
  return (
    <Fragment>
      <nav className={isSticky ? "nav sticky-nav" : "nav"}>
        <div className="lg:max-w-[1054px]  xl:max-w-[1271px] mx-auto px-4">
          <div className="flex justify-between items-center md:py-[10px]">
            <div className="flex space-x-7">
              <div>
                <Link to={"/"} className="flex items-center py-4 px-2">
                  <img
                    src={image.logoNove}
                    alt="Logo nove"
                    className="mr-2 hover:scale-110 transition ease duration-500"
                  />
                </Link>
              </div>
            </div>
            <div className="hidden lg:flex lg:flex-rows items-center  xl:space-x-12">
              <ul className="lg:flex lg:items-center">
                {data.navData.map((data) => (
                  <LinkItem
                    key={data.id}
                    isOpen={true}
                    item={t(`${data.trans}`)}
                    path={data.path}
                  />
                ))}
                <DropdownLinkItem
                  basePath={"service"}
                  list={data.subLink}
                  textLink={t("services")}
                  path="/service"
                  transList={"nosService"}
                />
                <DropdownLinkItem
                  basePath={"nos-domaines"}
                  height={"300px"}
                  list={data.domainLink}
                  textLink={t("domaines")}
                  path="nos-domaines"
                  transList={"nosDomaine"}
                />
                <LinkItem path={"nos-projets"} item={t("projets")} />
                <Dropdown />
                <CallToAction />
              </ul>
            </div>
            <div className="lg:hidden flex items-center">
              <button
                className={`${
                  hasActive
                    ? "outline-none mobile-menu-button"
                    : "mobile-menu-button active"
                } block`}
                onClick={handleActive}
              >
                {hasActive ? (
                  <MdClose className="w-[35px] text-[#3998D4] h-full" />
                ) : (
                  <GiHamburgerMenu className="w-[35px] h-full text-[#3998D4]" />
                )}
              </button>
            </div>
          </div>
        </div>
        <div
          className={`${
            hasActive ? "mobile-menu active" : "mobile-menu overflow-y-scroll lg:hidden"
          }`}
        >
          <ul
            className={`${
              hasActive ? "mobile-menu-items active" : "mobile-menu-items"
            } space-y-10 text-center`}
          >
            {data.navData.map((data) => (
              <MobileLink
                key={data.id}
                item={t(`${data.item}`)}
                hasActive={hasActive}
                setActive={setActive}
                path={data.path}
              />
            ))}
            <MobileDropDownLinkItem
              basePath="service"
              textLink="Nos Service"
              path="#"
              setOpen={setOpen}
              isOpened={isOpened}
              hasActive={hasActive}
              setActive={setActive}
              setIsOpen={setIsOpen}
              list={data.subLink}
            />
            <MobileDropDownLinkItemClose
              basePath="nos-domaines"
              textLink="Secteur D'activite"
              path="#"
              isOpened={isOpened}
              setOpen={setOpen}
              isItOpen={isItOpen}
              setIsOpen={setIsOpen}
              list={data.domainLink}
            />
            <MobileLink item={t("projets")} path={"/nos-projets"} />
            <Link to={'/contact'} href={"#"} 
            className="py-[13px] rounded-md px-[40px] lg:px-[20px] xl:px-[30px] lg:py-[24px] md:px-[30px] md:py-[25px] md:h-12 text-white bg-blue-500 hover:bg-white hover:text-blue-500 border-2 border-transparent transition duration-500 md:flex md:items-center md:justify-center block mx-4"
            onClick={() => setActive(false)}
            >{t('contactezNous')}</Link>
          </ul>
        </div>
      </nav>
    </Fragment>
  );
};

export default Navbar;
