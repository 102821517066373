import React, {Fragment} from 'react';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from 'react-i18next';


import { data, image } from '../../utils/constants';



const Patenaire = () => {

  const { t } = useTranslation("home");

  const settings = {
    infinite: true,
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 3000,
  }

  const imgs = [
    image.logoCRM,
    image.logoCrebat,
    image.logoAspac,
    image.logoCrid,
    image.logoUNDPS
  ]

  return (
    <Fragment>
        <section className="patenaire bg-blue-900 py-[90px] lg:py-[115px]">
            <div className="content-wrapper">
               <h2 className="headline-border" style={{color: '#ffffff', borderLeftColor: '#FFFFFF'}}>{t('patenaire')}</h2>

               <div className="logo-section mt-[80px] md:mt-[106px]">
                <ul className="logo-container hidden md:flex md:flex-wrap md:gap-y-5 lg:flex-nowrap md:gap-x-8 lg:gap-x-4 xl:gap-x-8 md:items-center md:justify-between ">
                  <li className="hover:scale-110 transition-all"><a href="https://microfinance-ccm.com”" className="w-[180px] lg:w-[140px] xl:w-[180px] h-[180px] block"><img src={image.logoCRM} alt="logo Engie" className="w-full h-full object-contain"/></a></li>
                  <li className="hover:scale-110 transition-all"><a href="http://www.groupecrebat.com/" target="_blank" className="w-[180px] lg:w-[140px] xl:w-[180px] h-[180px] block"><img src={image.logoCrebat} alt="logo Razel" className="w-full h-full object-contain"/></a></li>
                  <li className="hover:scale-110 transition-all"><a href="https://aspacintl.com/" target="_blank" className="w-[180px] h-[180px] lg:w-[140px] xl:w-[180px] block"><img src={image.logoAspac} alt="logo Engie" className="w-full h-full object-contain"/></a></li>
                  <li className="hover:scale-110 transition-all"><a href="https://crid-cam.net/" target="_blank" className="w-[180px] h-[180px] lg:w-[140px] xl:w-[180px] block"><img src={image.logoCrid} alt="logo Razel" className="w-full h-full object-contain"/></a></li>
                  <li className="hover:scale-110 transition-all"><a href="https://www.unops.org/" target="_blank" className="w-[180px] h-[180px] lg:w-[140px] xl:w-[180px] block"><img src={image.logoUNDPS} alt="logo Razel" className="w-full h-full object-contain"/></a></li>
                </ul>

                <div className='imgslider md:hidden'>
                  {/* const {arrrows} = ...settings */}
                 <Slider {...settings}>
                    {
                      imgs.map((item) => (
                        <div key={item} className="">
                          <img src={item} alt="logo patenaires" className=' w-full h-[200px] object-contain'/>
                        </div>
                      ))
                    }
                 </Slider>
                </div>
               </div>
            </div>
        </section>
    </Fragment>
  )
}

export default Patenaire