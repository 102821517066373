import { Suspense, useEffect } from "react";

import {
  Routes,
  Route,
  BrowserRouter as Router,
  useLocation,
  Navigate,
  BrowserRouter,
} from "react-router-dom";
import "./i18n";
import { AnimatePresence } from "framer-motion";
import { HelmetProvider } from "react-helmet-async";

import Home from "./Pages/Home/Home";
import LayoutTemplate from "./Pages/LayoutTemplate";
import Apropos from "./Pages/a-porpos/Apropos";
import ContactezNous from "./Pages/contactez-nous/ContactezNous";
import Service from "./Pages/nos-services/Service";
import Projet from "./Pages/projets/Projet";
import Domaines from "./Pages/nos-domaines/Domaines";
import ProjetDetails from "./Pages/projet-details/ProjetDetails";
import { Navbar } from "./components";
import ScroolToTop from "./components/ScroolToTop";
import ServiceDesc from "./Pages/nos-services/ServiceDesc";
import ServiceDescManagement from "./Pages/nos-services/ServiceDescManagement";
import ServiceDescAudit from "./Pages/nos-services/ServiceDescAudit";
import DomaineContent from "./Pages/nos-domaines/DomaineContent";
import DomaineContentConseil from "./Pages/nos-domaines/DomaineContentConseil";
import DomaineContentMaitrise from "./Pages/nos-domaines/DomaineContentMaitrise";
import DomaineContentAssisTechnique from "./Pages/nos-domaines/DomainContentAssisTechnique";
import DomaineContentAssistance from "./Pages/nos-domaines/DomaineContentAssistance";
import AnimatedRoutes from "./Pages/AnimatedRoutes";

function App() {
  return (
    <Suspense fallback={null}>
      <HelmetProvider>
        <div className="App">
          <AnimatePresence>
            <BrowserRouter>
              <ScroolToTop>
                <Navbar />
                <AnimatedRoutes />
              </ScroolToTop>
            </BrowserRouter>
          </AnimatePresence>
        </div>
      </HelmetProvider>
    </Suspense>
  );
}

export default App;
