import React, { useState } from 'react';

import { NavLink, Link } from 'react-router-dom';
import { IoIosArrowDown } from "react-icons/io";

const MobileDropDownLinkItemClose = ({ list, path, basePath, textLink, hasActive, setActive, setOpen, isOpened, setIsOpen, isItOpen }) => {
    

    const handleHasActive = () => {
        if (hasActive === true) {
          setActive(false);
        } else (setActive(true))
    }

    const handleIsOpen = () => {
        setIsOpen((prevState) => !prevState);
        setOpen(false);
    }

    return (
        <li className='link-dropdown flex justify-center items-center flex-col px-3'>
            <a 
            href={path}
            onClick={() => {
                setIsOpen((prevState) => !prevState)
                setOpen(false);
            }}
            className={isItOpen ? "link-item active font-bold" : "link-item justify-center"}>
                <span className="flex items-center ">
                    <span className="block">{textLink}</span> 
                    <IoIosArrowDown onClick={() => setOpen(true)} className={isItOpen ? "rotate-180 transition-all" : "rotate-0 text-blue-500 font-bold block ml-2"}/>
                </span>
            </a>
            <ul className={isItOpen ? 'active mt-2 rounded-md': ' '}>
                {
                    list.map((item) => (
                        <li key={item.id} className="item-select">
                            <Link to={`/${basePath}/${item.path}`}
                            className="text-[13px]"
                            onClick={() => {
                                handleHasActive()
                                setIsOpen(false)
                            }}
                            >{item.link}</Link>
                        </li>
                    ))
                }
            </ul>
        </li>
    )
}

export default MobileDropDownLinkItemClose