import React, { Fragment } from "react";

import { Hero } from "../../components";
import SEO from "../../SEO";
import AnimatePage from "../../components/UI/AnimatePage";
import { SectionIntro, NosServices, Patenaire, Testimonial } from "./index";
import ProjetSection from "./ProjetSection";

const Home = () => {
  return (
    <Fragment>
      <SEO
        title="nove engineering"
        description="NOVE et la prestation en ces domaines"
        type="website"
        img="https://img.freepik.com/premium-photo/engineer-black-women-worker-professional-woman-afican-mechanical-maintenance-work-factory_43300-3538.jpg?w=360"
      />
      <Hero />
   
      <SectionIntro />
      <NosServices />
      <ProjetSection />
      <Patenaire />
      <Testimonial />
    </Fragment>
  );
};

export default Home;
