import React, { Fragment } from 'react';

import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Header } from '../../components';
import AnimatePage from '../../components/UI/AnimatePage';
import SEO from '../../SEO';
import { image } from '../../utils/constants';
import ServiceDesc from './ServiceDesc';

const Service = () => {
  const { t } = useTranslation("service");
  return (
    <Fragment>
      <SEO
      title="service nove"
      description="NOVE et la description de ses services"
      type="website"
      img="https://img.freepik.com/premium-photo/engineer-black-women-worker-professional-woman-afican-mechanical-maintenance-work-factory_43300-3538.jpg?w=360"
      />

        <Header
        img={image.imgService}
        title={t("serviceHeadline")}
        />
        <Outlet />

    </Fragment>
  )
}

export default Service
