import React, {Fragment} from 'react';

import { NavLink } from 'react-router-dom';

const MobileLink = ( {item, path, setActive, hasActive} ) => {

  const handleHasActive = () => {
    if (hasActive === true) {
      setActive(false);
    } else (setActive(true))
  }
  return (
    <Fragment>
        <li className="">
            <NavLink 
            end
            to={path}
            onClick={handleHasActive}
            className={({isActive}) => isActive ? "link-item active" : "link-item"}>{item}</NavLink>
        </li>
    </Fragment>
  )
}

export default MobileLink