import React, { Fragment } from "react";
import { useState } from "react";
import { image } from "../../utils/constants";

const ServiceRendue = ({ project }) => {
  
  return (
    <Fragment>
      <section className="service-rendue py-[90px] lg:py-[115px]">
        <div className="content-wrapper">
          <h2 className="headline-border">SERVICE RENDUE</h2>

          <div className="service-container mt-[40px] lg:mt-[115px] max-w-[969px] mx-auto relative h-[500px] lg:h-[762px]">
            <div className="service-img absolute top-0 left-0 w-[626px] h-[546px] hidden lg:block shadow-2xl">
              <img
                src={/* list[indexCount] */ image.auditEtConseil}
                alt="img service rendue"
                className="w-full h-full object-cover"
              />
            </div>
            <div className="service-description absolute bottom-0 w-[100%] right-0 lg:w-[543px] h-[auto] lg:h-[546px] py-[100px] lg:py-[100px] lg:px-[77px] px-[30px] shadow-lg bg-white">
              <h3 className="heading text-[32px] lg:text-[48px] font-light">
                {project.secteurDactivite}
              </h3>
              <ul className="service-list list-disc mt-[41px] space-y-8">
                {
                  project.servicesRendus.map((item , index)=>(<><li index={index}>{item}</li></>))
                }
                
              </ul>
              <span className="line block h-1 absolute bottom-0 right-0 bg-blue-500  w-[70%]"></span>
            </div>
          </div>
          {/*          <div className="navigation-btn flex gap-x-2">
            <span
              onClick={() => lengthCheckDecrement()}
              className="w-[50px] h-[50px] rounded-full bg-blue-300 flex justify-center items-center cursor-pointer hover:text-opacity-80 transition-all"
            >
              <img
                src={image.arrowLeft}
                alt="icon arrow left"
                className="w-[20px]"
              />
            </span>
            <span
              onClick={() => lengthCheckIncrement()}
              className="w-[50px] h-[50px] rounded-full bg-blue-300 flex justify-center items-center cursor-pointer hover:text-opacity-80 transition-all"
            >
              <img
                src={image.arrowRight}
                alt="icon arrow right"
                className="w-[20px]"
              />
            </span>
          </div> */}
        </div>
      </section>
    </Fragment>
  );
};

export default ServiceRendue;
