import React, { Fragment } from 'react'
import { image } from '../../utils/constants'

const ProjetShowcase = ({ img, title, count, list }) => {
  console.log(list, count)
  return (
    <Fragment>
        <section className="show-case">
            <div className="img-show h-[400px] lg:h-[500px] content-wrapper">
                <img src={list[count]} alt={`${title}-${count}`} title={`${title}-count`} className="w-full h-full object-cover"/>
            </div>
        </section>
    </Fragment>
  )
}

export default ProjetShowcase