import React, { Fragment, useEffect } from "react";

import Aos from "aos";
import "aos/dist/aos.css";

const TeamCard = ({ img, content, name, title, id, subTitle }) => {
  useEffect(() => {
    Aos.init({ once: true });
  });
  return (
    <Fragment>
      <div
        className="card-container h-full"
        data-aos="zoom-out"
        data-aos-delay={`${300 * id}`}
      >
        <div className="team-card">
          <div className="team-title">
            <div className="details">
              <h3>{name}</h3>
              <h5>{title}</h5>
              <h5>{subTitle}</h5>
            </div>
            <div className="img">
              <img src={img} alt="image John Doe" />
            </div>
          </div>
          <div className="team-desc">
            <p>{content}</p>
          </div>
        </div>
        {/*             <div className="team-img hidden lg:block">
                <img src={img} alt="image John Doe" />
            </div> */}
      </div>
    </Fragment>
  );
};

export default TeamCard;
