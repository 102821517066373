import React from "react";

import {
  Routes,
  Route,
  BrowserRouter as Router,
  Navigate,
  useLocation,
  BrowserRouter,
} from "react-router-dom";
import { AnimatePresence, AnimateSharedLayout, motion } from "framer-motion";

import { HelmetProvider } from "react-helmet-async";

import Home from "./Home/Home";
import LayoutTemplate from "./LayoutTemplate";
import Apropos from "./a-porpos/Apropos";
import ContactezNous from "./contactez-nous/ContactezNous";
import Service from "./nos-services/Service";
import Projet from "./projets/Projet";
import Domaines from "./nos-domaines/Domaines";
import ProjetDetails from "./projet-details/ProjetDetails";
import { Navbar } from "../components";
import ScroolToTop from "../components/ScroolToTop";
import ServiceDesc from "./nos-services/ServiceDesc";
import ServiceDescManagement from "./nos-services/ServiceDescManagement";
import ServiceDescAudit from "./nos-services/ServiceDescAudit";
import DomaineContent from "./nos-domaines/DomaineContent";
import DomaineContentConseil from "./nos-domaines/DomaineContentConseil";
import DomaineContentMaitrise from "./nos-domaines/DomaineContentMaitrise";
import DomaineContentAssisTechnique from "./nos-domaines/DomainContentAssisTechnique";
import DomaineContentAssistance from "./nos-domaines/DomaineContentAssistance";
import { useTranslation } from "react-i18next";

function AnimatedRoutes() {
  const i18n = useTranslation();
  const location = useLocation();
  const boxAnimation = {
    key: location.pathname,
    initial: { x: "-50px", y: 5, opacity: 0 },
    animate: { x: 0, y: 5, opacity: 1 },
    exit: {
      x: "50px",
      y: 5,
      opacity: 0,
      transition: { type: "tween", ease: "linear", duration: 0.5 },
    },
    transition: { type: "tween", duration: 1, ease: "linear" },
  };

  return (
    <AnimateSharedLayout>
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          <Route
            path="/"
            element={
              <motion.div {...boxAnimation}>
                <LayoutTemplate />
              </motion.div>
            }
          >
            <Route
              index
              element={
                <motion.div {...boxAnimation}>
                  <Home />
                </motion.div>
              }
            />
            <Route
              path="contact"
              element={
                <motion.div {...boxAnimation}>
                  <ContactezNous />
                </motion.div>
              }
            />
            <Route
              path="a-propos"
              element={
                <motion.div {...boxAnimation}>
                  <Apropos />
                </motion.div>
              }
            />
            <Route
              path="service"
              element={
                <motion.div {...boxAnimation}>
                  <Service />
                </motion.div>
              }
            >
              <Route
                path="etudes-structures-dexecusion"
                element={
                  <motion.div {...boxAnimation}>
                    <ServiceDesc />
                  </motion.div>
                }
              />
              <Route
                path="management-de-projet"
                element={
                  <motion.div {...boxAnimation}>
                    <ServiceDescManagement />
                  </motion.div>
                }
              />
              <Route
                path="audit-et-conseils"
                element={
                  <motion.div {...boxAnimation}>
                    <ServiceDescAudit />
                  </motion.div>
                }
              />
            </Route>
            <Route
              path="/nos-projets"
              element={
                <motion.div {...boxAnimation}>
                  <Projet />
                </motion.div>
              }
            />
            <Route
              path="/nos-projets/:projectId"
              element={
                <motion.div {...boxAnimation}>
                  <ProjetDetails />
                </motion.div>
              }
            />
            <Route
              path="nos-domaines"
              element={
                <motion.div {...boxAnimation}>
                  <Domaines />
                </motion.div>
              }
            >
              <Route
                index
                path="batiments"
                element={
                  <motion.div {...boxAnimation}>
                    <DomaineContent />
                  </motion.div>
                }
              />
              <Route
                path="infracstructure"
                element={
                  <motion.div {...boxAnimation}>
                    <DomaineContentConseil />
                  </motion.div>
                }
              />
              <Route
                path="ouvrage-d'art"
                element={
                  <motion.div {...boxAnimation}>
                    <DomaineContentMaitrise />
                  </motion.div>
                }
              />
              <Route
                path="amenagement-hydrolique"
                element={
                  <motion.div {...boxAnimation}>
                    <DomaineContentAssistance />
                  </motion.div>
                }
              />
            </Route>
            <Route
              path="/proj-details"
              element={
                <motion.div {...boxAnimation}>
                  <ProjetDetails />
                </motion.div>
              }
            />
            <Route
              path="*"
              element={
                <motion.div {...boxAnimation}>
                  <Navigate to={"/"} replace />
                </motion.div>
              }
            />
          </Route>
        </Routes>
      </AnimatePresence>
    </AnimateSharedLayout>
  );
}

export default AnimatedRoutes;
