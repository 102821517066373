import React, { Fragment } from 'react';

import i18next from 'i18next';
import { IoLanguageOutline } from "react-icons/io5";

import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';

const Dropdown = () => {
    const { i18n } = useTranslation(["home"]);
    const lngs = ['en', 'fr'];
    const [value, setValue] = useState('Fr');
    // checking the if language is valid one
    useEffect(() => {
        if(localStorage.getItem("i18nextLng")?.length > 2) {
            i18next.changeLanguage("en");
        }
    }, []);
    
    const handleValue = (event) => {
        setValue(event.target.textContent);
        console.log(i18n.changeLanguage(event.target.textContent))
    }
    
  return (
    <Fragment>
        <li className="li-dropdown text-blue-600 relative cursor-pointer uppercase text-[23px] md:text-[16px] lg:mx-4 lg:pr-4">
        <span className="flex gap-2 items-center">
            <span><IoLanguageOutline className="text-blue-600"/></span>
            <span>{value}</span>
        </span>
        <ul className="lg:max-w-[500px] border-t-2 border-blue-600 bg-white lg:absolute lg:top-[25px] lg:-left-[10px] text-slate-800 dropdown-content transition-all z-10 shadow-lg">
            {
                lngs.map((lng) => {
                    return (
                        <li key={lng} className='py-2 px-5 text-blue-600 cursor-default md:text-base transition-colors uppercase' value={localStorage.getItem("i18nextLng")} onClick={handleValue}>{lng}</li>
                    )
                })
            }
        </ul>
        </li>
    </Fragment>
  )
}

export default Dropdown