import React, { Fragment, useEffect } from "react";

import Aos from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";

import { data, image } from "../../utils/constants";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const Mission = () => {
  const { t } = useTranslation("about-us");
  useEffect(() => {
    Aos.init({ once: true });
  });
  return (
    <Fragment>
      <section className="mission bg-white py-[90px] lg:[py-100px]">
        <div className="content-wrapper">
          <h2 className="headline-border">{t("aboutMissionsHeading")}</h2>

          <div className="mission-container min-h-full sm:grid sm:grid-cols-2 sm:gap-x-[40px] lg:justify-between mt-[121px]">
            <div
              className="mission-img md:h-[100%] xl:h-[658px] lg:w-full xl:w-[538px] "
              data-aos="zoom-out-up"
              data-aos-delay="400"
            >
              <img
                src={image.imgHands}
                alt="image holding hands"
                className="w-full object-cover h-full"
                title="image holding hands"
              />
            </div>
            <div className="mission-text sm:flex sm:flex-col lg:flex lg:flex-col sm:gap-10 lg:gap-y-5 xl:gap-y-[50px] mt-8 xl:mt-0">
              {data.missions.map((mission, index) => (
                <div
                  key={mission.id}
                  className="mission-desc mb-4 lg:mb-0"
                  data-aos="fade-in"
                  data-aos-delay={`${300 * mission.id}`}
                >
                  <h3 className="mb-[24px] font-bold text-[24px] text-black-300 cursor-pointer transition-all hover:pl-4 hover:text-blue-600 hover:border-l-4 border-blue-600">
                    <HashLink to={mission.idLink}
                    smooth={true}
                      >
                      {t(`aboutMission.${index}.title`)}
                    </HashLink>
                  </h3>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Mission;
