import { t } from 'i18next';
import React, { Fragment } from 'react'

import { useTranslation } from 'react-i18next';

import { Header } from '../../components';
import AnimatePage from '../../components/UI/AnimatePage';
import SEO from '../../SEO';
import { image } from '../../utils/constants';
import About from './About';
import Equipe from './Equipe';
import Mission from './Mission';
import Valeur from './Valeur';

const Apropos = () => {
  const { t } = useTranslation("about-us");
  return (
    <Fragment>
      <SEO
      title="a propos nove"
      description="Le succès n'est pas définitif; l'échec n'est pas fatal : c'est le courage de continuer qui compte"
      type="website"
      img="https://img.freepik.com/premium-photo/engineer-black-women-worker-professional-woman-afican-mechanical-maintenance-work-factory_43300-3538.jpg?w=360"
      />
        <Header
        img={image.imgAbout}
        title={t('headerHeading')}
        />
        <About />
        <Valeur />
        <Mission />
        <Equipe />
    </Fragment>
  )
}

export default Apropos
