import React, { Fragment } from 'react';

import { useTranslation } from 'react-i18next';

import CardService from '../../components/UI/CardService'
import { data } from '../../utils/constants'
import ServiceNav from './ServiceNav'

const ServiceDesc = () => {
    const { t } = useTranslation("service");
  return (
    <Fragment>
        <section className="py-[90px] lg:py-[115px] xl:pt-[115px]">
            <div className="relative">
            <div className=" ">
                <div className=" px-8 lg:px-0 text-area-desc lg:max-w-[884px] lg:mx-auto lg:my-0">
                    <h2 className="headline-border">{t('serviceHeadlineDescription.serviceManagement.name')}</h2>
                    <p className="text-black_400 py-[90px]">{t('serviceHeadlineDescription.serviceManagement.desc')}</p>
                </div>

                <div className="relative flex 2xl:max-w-[1500px] 2xl:mx-auto">
                    <ServiceNav navList={data.linkManagement} linkName="navLinksManagement"/>
                    <div className="relative px-8 lg:px-0 lg:max-w-[884px] lg:mx-auto 2xl:translate-x-[-12%] lg:translate-x-[0%]">
                        {
                            data.servicesManagement.map((service, index) => (
                                <CardService 
                                key={service.id}
                                serviceTrans="serviceManagementProjet"
                                idLink={service.idLink}
                                title={t(`serviceManagementProjet.projectList.${index}.heading`)}
                                content={t(`serviceManagementProjet.projectList.${index}.desc`)}
                                list={service.imgs}
                                />
                            ))
                        }
                    </div>
                </div>
                
            </div>
            </div>
        </section>
    </Fragment>
  )
}

export default ServiceDesc