import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { act } from "react-dom/test-utils";
import { useTranslation } from "react-i18next";
import { NavLink, Link } from "react-router-dom";

const DropdownLinkItem = ({ list, textLink, path, transList, basePath }) => {
  const { t } = useTranslation("home");
  const handleClick = (event)=>{
      event.preventDefault() ;
  }
  return (
    <li className="link-dropdown"  onClick={handleClick}>
      <NavLink
        to={path} 
    
        className={({ isActive }) =>
          isActive
            ? "link-item active font-bold"
            : "link-item hover:text-blue-500"
        }

        onClick= {(event)=>{event.preventDefault()}}

       
      >
        {`${textLink}`}
      </NavLink>
      <ul className={``}>
        {list.map((item, index) => {
            item.id =item.id+1 ;
            return (
          <li key={item.id} className="item-select">
            <NavLink to={`/${basePath}/${item.path}`} end>
              {t(`${transList}.${index}`) }
            </NavLink>
          </li>
        )})}
      </ul>
    </li>
  ); 
};

export default DropdownLinkItem;