import React, { Fragment, useContext, useState } from "react";

import { useParams } from "react-router-dom";

import { data, image } from "../../utils/constants";
import { Header } from "../../components";
import ProjetStats from "./ProjetStats";
import SimilarCategory from "./SimilarCategory";
import ProjetShowcase from "./ProjetShowcase";
import ServiceRendue from "./ServiceRendue";
import SEO from "../../SEO";
import NavBtn from "./NavBtn";
import { ProjectContext, DomaineContext } from "../projets/Projet";

const ProjetDetails = () => {
  const { projectId } = useParams();
  const [indexCount, setIndexCount] = useState(0);
  const projects = useContext(ProjectContext);
  const project = projects[parseInt(projectId)-1];
  const domaines = useContext(DomaineContext);
  let ProjetEnQuestion = [project.name];
  let projetServiceRendue = [project.imgs];
 
  const similarProjet =[] ;

  projects.map((item )=>{
    if(project.domaineId== item.domaineId && project.id !== item.id){
            similarProjet.push(item) ;
    }return
  })


  return (
    <Fragment>
      <SEO
        title={`${ProjetEnQuestion[0].toLowerCase()}`}
        description="projet details"
        type="website"
        img="https://img.freepik.com/premium-photo/engineer-black-women-worker-professional-woman-afican-mechanical-maintenance-work-factory_43300-3538.jpg?w=360"
      />
      <Header title={project.name} img={project.imgs[0]} />
      <ProjetStats project={project} />
      <ServiceRendue project={project} />
      <ProjetShowcase
        list={projetServiceRendue[0]}
        count={indexCount}
        title={ProjetEnQuestion[0]}
      />
      <NavBtn
        projetList={projetServiceRendue}
        setIndexCount={setIndexCount}
        indexCount={indexCount}
        listLength={projetServiceRendue.length - 1}
      ></NavBtn>
      <SimilarCategory similarProjet = {similarProjet} />
    </Fragment>
  );
};

export default ProjetDetails;
