import React , {Fragment} from "react";
import { image } from "../../utils/constants";


export default function NavBtn({
    projetList,
    indexCount,
    setIndexCount,
    listLength,
  }) {
 
        const list = [];
        projetList.map((item) => {
          item.map((subItem) => {
            list.push(subItem);
          });
        });
      
        const lengthCheckDecrement = () => {
          setIndexCount(indexCount - 1);
          if (indexCount <= 0) {
            setIndexCount(listLength+1);
          }
        };
      
        const lengthCheckIncrement = () => {
          setIndexCount(indexCount + 1);
          if (indexCount > listLength) {
            setIndexCount(0);
          }
        }

  return (
    <Fragment>
      <div className="navigation-btn flex gap-x-2 mx-auto mt-10 sm:max-w-[400px] md:max-w-[700px] lg:max-w-[900px] xl:max-w-[1152px]  ">
        <span
          onClick={() => lengthCheckDecrement()}
          className="w-[50px] h-[50px] rounded-full bg-blue-300 flex justify-center items-center cursor-pointer hover:text-opacity-80 transition-all"
        >
          <img
            src={image.arrowLeft}
            alt="icon arrow left"
            className="w-[20px]"
          />
        </span>
        <span
          onClick={() => lengthCheckIncrement()}
          className="w-[50px] h-[50px] rounded-full bg-blue-300 flex justify-center items-center cursor-pointer hover:text-opacity-80 transition-all"
        >
          <img
            src={image.arrowRight}
            alt="icon arrow right"
            className="w-[20px]"
          />
        </span>
      </div>
    </Fragment>
  );
}
