import React, { useState } from "react";

import { NavLink, Link } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";

const MobileDropDownLinkItem = ({
  list,
  path,
  basePath,
  textLink,
  hasActive,
  setActive,
  setOpen,
  isOpened,
  setIsOpen,
}) => {
  const handleHasActive = () => {
    if (hasActive === true) {
      setActive(false);
    } else setActive(true);
  };

  const handleStateChange = () => {
    setOpen(!isOpened);
    setIsOpen(false);
  };
  return (
    <li className="link-dropdown flex justify-center items-center flex-col px-3">
      <a
        href={path}
        onClick={() => handleStateChange()}
        className={
          isOpened ? "link-item active font-bold" : "link-item justify-center"
        }
      >
        <span className="flex items-center ">
          <span className="block">{textLink}</span>
          <IoIosArrowDown
            onClick={() => setOpen(true)}
            className={
              isOpened
                ? "rotate-180 transition-all"
                : "rotate-0 text-blue-500 font-bold block ml-2"
            }
          />
        </span>
      </a>
      <ul className={isOpened ? "active mt-2 rounded-sm" : ""}>
        {list.map((item) => (
          <li key={item.id} className="item-select">
            <Link
              to={`/${basePath}/${item.path}`}
              className="text-[12px]"
              onClick={() => {
                handleHasActive();
                setOpen(false);
              }}
            >
              {item.link}
            </Link>
          </li>
        ))}
      </ul>
    </li>
  );
};

export default MobileDropDownLinkItem;
