import React from "react";
import imageProjets from "./imageProjets";
const projects = [
  {
    id: 1,
    domaineId: 1,
    name: "PROJET DE CONSTRUCTION D’UN BATIMENT R+1 A USAGE D’HABITATION",
    description: "il s’agit d’un bâtiment RDC+1 avec une emprise de 150 m²",
    pays: "Cameroun",
    ville: "Douala",
    date: "2018 - 2019",
    secteurDactivite: "Bâtiment",
    servicesRendus: [
      "Conception" ,  "Etudes" , "Suivi et coordination des Travaux",
    ],
    client: "Un particulier",
    sousProjets: [],
    imgs: [imageProjets.planR1 , imageProjets.plan2R1 , imageProjets.batiment1R1 , imageProjets.batiment2R1],
    entreprise: "",
  },
  {
    id: 2,
    domaineId: 1,
    name: "PROJET DE CONSTRUCTION DE LA STATION TRADEX D’EKOREZOCK",
    description:
      "Construction de la boutique et de la baie de graissage de la station-service de Tradex.",
    pays: "Cameroun",
    ville: "Yaoundé",
    date: "2019",
    secteurDactivite: "Bâtiment à usage commercial",
    servicesRendus: ["Suivi et Coordination des Travaux"],
    client: "TRADEX",
    sousProjets: [],
    imgs: [imageProjets.tradexImg1 ,imageProjets.tradexImg2],
    entreprise: "CREBAT Sarl",
  },
  {
    id: 3,
    domaineId: 1,
    name: "PROJET DE CONSTRUCTION D’UN BATIMENT RDC +2 A USAGE D’HABITATION",
    description:
      "C’est un bâtiment à usage d’habitation implanté sur une emprise de 310m².",
    pays: "Cameroun",
    ville: "Yaoundé",
    date: "2017 - 2019",
    secteurDactivite: "Bâtiment",
    servicesRendus: [
      "Conception" ,  "Etudes" , "Suivi et coordination des Travaux",
    ],
    client: "Un particulier",
    sousProjets: [],
    imgs: [imageProjets.planR2 , imageProjets.batimentR2],
    entreprise: "",
  },
  {
    id: 4,
    domaineId: 4,
    name: "PROJET D’ALIMENTATION EN EAU POTABLE DU PORT AUTONOME DE KRIBI ET SES DEPENDANCES : RESERVOIR DE REPRISE DE 1500 M3",
    description:
      "Le réservoir de reprise de 1500 m3 est un ouvrage de retenue d’un diamètre intérieur de 25 m et d’une hauteur d’environ 5m.",
    pays: "Cameroun",
    ville: "KRIBI",
    date: "2020- 2021",
    secteurDactivite: "Ouvrage hydraulique",
    servicesRendus: ["Etudes" ,  "Plans d’exécutions"],
    client: "Port Autonome de Kribi (PAK)",
    sousProjets: [],
    imgs: [imageProjets.imgPak1 , imageProjets.imgPak2 ],
    entreprise: "ASPAC International",
  },
  {
    id: 5,
    domaineId: 4,
    name: "PORT AUTONOME DE KRIBI ET SES DEPENDANCES : FILTRE AUTONETTOYANT",
    description:
      "il s’agit d’un ouvragre support des filtres de grande capacité et à géométrie symétrique.",
    pays: "Cameroun",
    ville: "KRIBI",
    date: "2017 - 2019",
    secteurDactivite: "Ouvrage hydraulique",
    servicesRendus: ["Etudes" ,  "Plans d’exécutions"],
    client: "Port Autonome de Kribi (PAK)",
    sousProjets: [],
    imgs: [imageProjets.imgFiltre],
    entreprise: "ASPAC International",
  },

  {
    id: 6,
    domaineId: 1,
    name: "PROJET DE CONSTRUCTION DU SIEGE DU CENTRE FOR RESEARCH IN INFECTIOUS DISEASES-CRID",
    description:
      "Il s’agit d’un Bâtiment RDC+4 abritant le siège du CRID et implanté sur une superficie de 400 m².",
    pays: "Cameroun",
    ville: "Yaoundé",
    date: "2020 - 2021",
    secteurDactivite: "Bâtiment",
    servicesRendus: ["Etudes architecturales" , "exécutions"],
    client: "CENTRE FOR RESEARCH IN INFECTIOUS DISEASES-CRID",
    sousProjets: [],
    imgs: [imageProjets.immeubleCrid , imageProjets.plaqueCrid],
    entreprise: "",
  },
  {
    id: 7,
    domaineId: 4,
    name: " Wenchi Water Supply Project - GHANA : FILTRE AUTONETTOYANT",
    description:
      "Le process block est un ouvrage essentiel dans le processus de potabilisation de l’eau.",
    pays: "GHANA",
    ville: "Wenchi",
    date: "2022 - 2023",
    secteurDactivite: "Ouvrage hydraulique",
    servicesRendus: ["Etudes" ,  "plans d’exécutions"],
    client: "GHANA WATER COMPAGNY LIMITED",
    sousProjets: [],
    imgs: [imageProjets.wenchi1 , imageProjets.wenchi2],
    entreprise: "ASPAC International",
  },
  {
    id: 8 ,
    domaineId: 4,
    name: " PROJET D’ALIMENTATION EN EAU POTABLE DU PORT AUTONOME DE KRIBI ET SES DEPENDANCES : LE DESSABLEUR",
    description:
      "Le Dessableur est un ouvrage hydraulique principalement en béton armé et symétrie dans sa géométrie.",
    pays: "Cameroun",
    ville: "KRIBI",
    date: "2020-2021",
    secteurDactivite: "Ouvrage hydraulique",
    servicesRendus: ["Etudes" ,  "plans d’exécutions"],
    client: "Port Autonome de Kribi (PAK)",
    sousProjets: [],
    imgs: [imageProjets.dessableur1],
    entreprise: "ASPAC International",
  },

  {
    id: 9 ,
    domaineId: 1,
    name: "PROJET D’ALIMENTATION EN EAU POTABLE DU PORT AUTONOME DE KRIBI ET SES DEPENDANCES : BATIMENT ADMINISTRATIF",
    description:
      "il s’agit d’un bâtiment RDC+1.",
    pays: "Cameroun",
    ville: "KRIBI",
    date: "2019-2020",
    secteurDactivite: "Bâtiment",
    servicesRendus: ["Etudes" ,  "plans d’exécutions"],
    client: "Port Autonome de Kribi (PAK)",
    sousProjets: [],
    imgs: [imageProjets.baKribi],
    entreprise: "ASPAC International",
  },
];

const domaines = [
  {
    id:1 ,
    title : "batiment" ,
    link : "batiment" , 
  } ,
  {
    id: 2,
    title : "infrastructure" ,
    link : "infrastructure" 
  } ,
  {
    id: 3,
    title : "ouvrages d’art" ,
    link : "ouvrages d’art" 
  } , 
   {
    id: 4 ,
    title : "hydrolique" ,
    link : "hydrolique" 
  }

  
] ;
export default {
    projects ,
    domaines
};
