import React, { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';


import Typed from 'react-typed';
import 'react-typed/dist/animatedCursor.css';

const Hero = () => {

    const { t } = useTranslation("home");
    
  return (
    <Fragment>
        <div className="relative overflow-hidden overlay-img bg-no-repeat bg-cover h-screen md:h-[560px] lg:h-[657px] px-4 sm:p-6 lg:py-[181px]">
            <div className="absolute overlay top-0 right-0 bottom-0 left-0 px-2 w-full h-full overflow-hidden bg-fixed">
                <div className=" content-wrapper flex justify-start items-center h-[657px] px-4">
                    <div className="text-left text-white">
                        <p className="mb-5 lg:mb-10 text-blue-300 lg:text-2xl pl-3 border-l-4 border-l-blue-400">
                            {t('subHeadLine')}
                        </p>
                        <h1 className="text-[35px] md:text-[50px] lg:w-[646px] xl:w-[600px] lg:text-[60px] font-bold tracking-tight md:leading-[63px] lg:leading-[73px] text-white">
                            {t('headline')}<br/>
                            <Typed 
                            strings={[`${t('highLight2')}`, `${t('highLight1')}`]}
                            typeSpeed={200}
                            backSpeed={200}
                            fadeOut={false}
                            showCursor
                            style={{color: '#2ca2df'}}
                            loop
                            cursorChar='|'
                            />
                        </h1>
                    </div>
                </div>
            </div>
        </div>
    </Fragment>
  )
}

export default Hero