import React, { Fragment, useState } from "react";

import { useTranslation } from "react-i18next";
import axios, { isCancel, AxiosError } from "axios";
import { Input } from "../../components";
import { data, image } from "../../utils/constants";
import apiConfig from "../../apiConfig.json";
import Swal from "sweetalert2";

const ContactForm = () => {
  const { t } = useTranslation("contact");

  //state to controle texarea
  const [message, setMessage] = useState("");

  const [loader, setLoader] = useState(false);
  let contactClass = "";
  const mes = () => <> {t("contactSlogan")} </>
  
  const handleLoader = () => {
    setLoader((loader) => !loader);
  };

  const [dataObject, setData] = useState({
    name: "",
    email: "",
    emailEntreprise: apiConfig.enPoints.sendMail.emailEntreprise,
    object: "",
    objectConfirmation: apiConfig.enPoints.sendMail.object,
    message: "",
    messageConfirmation: apiConfig.enPoints.sendMail.messageUtilisateur,
  });

  const handleTexarea = (e) => {
    e.preventDefault();
    const value = e.target.value;
    setMessage(value);
  };

  const handleSubmit = async (e) => {
    setLoader(true) ;
    e.preventDefault();

    const name = e.target.name.value;
    const email = e.target.email.value;
    const message = e.target.message.value;
    const object = e.target.object.value;

    setData({
      ...dataObject,
      name: name,
      email: email,
      message: message,
      object: object,
    });

    console.log(dataObject) ;
    /*  on essaie de s' Authentifier
     */
    try {
      const res = await axios.post(apiConfig.enPoints.login.url, {
        email: apiConfig.enPoints.login.email,
        password: apiConfig.enPoints.login.passWord,
      });

      /* si on reuissit a s'authentifier */
      if (res.data.status) {
        /* on essaie l'envoie du mail */
        try {
          const response = await axios.post(
            apiConfig.enPoints.sendMail.url,
            dataObject,
            {
              headers: {
                Accept: "application/json",
                Authorization: `Bearer ${res.data.token}`,
                "content-type": "application/json",
              },
            }
          );

          if (response.data.status) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Your email has been sent",
              showConfirmButton: false,
              timer: 3000,
            });
          }

          console.log(response.data.status);
        } catch (error) {
          console.log(error);

          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "an error has occurred, check that you have filled in the form correctly",
            footer: '<a href=""></a>',
          });
        }
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Server error please try again later",
        footer: '<a href=""></a>',
      });
    }

    setLoader(false) ;
  };

  return (
    <Fragment>
      <section className="py-[90px] lg:py-[115px] bg-black-50">
        <div className="content-wrapper">
          <div className="flex flex-col gap-y-8 md:grid md:grid-cols-2 md:gap-x-8 lg:flex lg:flex-row lg:gap-x-[54px] xl:gap-x-[74px]">
            <div className="address w-4/4 lg:w-2/4 relative">
              <div className="address-detail relative z-10 h-full py-[80px] px-[40px] lg:px-[44px] lg:pt-[88px] xl:px-[88px] lg:pb-[314px] xl:">
                <div className="desc">
                  <h4 className="uppercase text-white font-bold text-[20px]">
                    NOVE Engineering
                  </h4>
                  <p className="text-black-50 py-4">{t("contactSlogan")}</p>

                  <ul className="detail flex flex-col gap-y-5 py-8">
                    <li className="flex items-center gap-2">
                      <img src={image.iconPhone} alt="icon phone" />
                      <span className="w-[150px] text-sm block text-white">
                        +237 675 147 996
                      </span>
                    </li>
                    <li className="flex items-center gap-2">
                      <img src={image.iconEmail} alt="icon email" />
                      <span className="w-[150px] break-words lg:break-normal text-white text-sm">
                        contact@novengineering.com
                      </span>
                    </li>
                    <li className="flex items-center gap-2">
                      <img src={image.iconLocation} alt="icon location" />
                      <span className="w-[150px] text-sm block text-white">
                        {t("contactAddress")}
                      </span>
                    </li>
                  </ul>

                  <span className="bottom-block w-full h-[26px] lg:h-[52px] left-0 bg-blue-900 block absolute bottom-0"></span>
                </div>
              </div>
              <span className="square-block hidden w-full h-[709px] xl:h-[714px] lg:block lg:absolute lg:top-[22px] lg:left-[24px] bg-blue-500 opacity-50"></span>
            </div>

            <div className="form-container w-4/4 lg:w-2/4">
              <form
                className="space-y-[20px] md:space-y-[30px] xl:space-y-[60px]"
                onSubmit={(e) => handleSubmit(e)}
              >
                {data.inputs.map((input, index) => (
                  <Input
                    key={input.id}
                    label={t(`contactInputObject.${index}.name`)}
                    type={input.type}
                    name={input.name}
                  />
                ))}
                <div className="form-group flex flex-col gap-y-3">
                  <textarea
                    name="message"
                    onChange={(e) => handleTexarea(e)}
                    value={message}
                    placeholder="Message"
                    className="h-[150px] focus:ring-1 md:h-[300px] lg:h-[200px] focus:ring-blue-200 focus:outline-blue-500 px-[20px] py-[16px]"
                  ></textarea>
                </div>
                <div className=" flex flex-col mt-[30px] lg:mt-[91px]">
                  <button
                    type="submit"
                    className={
                      loader
                        ? "animate-pulse bg-blue-500 text-white p-[16px] h-[57px] hover:bg-white hover:text-blue-500 border-2 border-transparent hover:border-2 hover:border-blue-500 transition duration-500"
                        : " bg-blue-500 text-white p-[16px] h-[57px] hover:bg-white hover:text-blue-500 border-2 border-transparent hover:border-2 hover:border-blue-500 transition duration-500"
                    }
                    disabled ={loader}
                  >
                    {t("contactUs")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default ContactForm;
