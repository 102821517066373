import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import projectData from "../../utils/constants/projectData";

import { Header } from "../../components";
import SEO from "../../SEO";
import AnimatePage from "../../components/UI/AnimatePage";
import { image } from "../../utils/constants";
import ProjetSectionl from "./ProjetSectionl";

export const ProjectContext = React.createContext(projectData.projects);
export const DomaineContext = React.createContext(projectData.domaines);

const Projet = () => {
  const { t } = useTranslation("projets");

  return (
    <Fragment>
      <SEO
        title="projet nove"
        description="Projet que NOVE vous offre"
        type="website"
        img="https://img.freepik.com/premium-photo/engineer-black-women-worker-professional-woman-afican-mechanical-maintenance-work-factory_43300-3538.jpg?w=360"
      />

        <Header img={image.imgProjet} title={t("projetHeadingTitle")} />
        <ProjectContext.Provider value={projectData.projects}>
          <DomaineContext.Provider value={projectData.domaines}>
            <ProjetSectionl />
          </DomaineContext.Provider>
        </ProjectContext.Provider>

    </Fragment>
  );
};

export default Projet;

